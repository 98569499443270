import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {HomeLayoutComponent} from './home-layout.component';
import {HeaderComponent} from './components/header/header.component';
import {FooterComponent} from './components/footer/footer.component';
import {MobileMenuComponent} from './components/mobile-menu/mobile-menu.component';
import {SharedModule} from '../../shared/shared.module';
import {BaseTranslateModule} from '../../shared/base-translate.module';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    SharedModule,
    BaseTranslateModule
  ],
  declarations: [
    HomeLayoutComponent,
    HeaderComponent,
    FooterComponent,
    MobileMenuComponent
  ],
  providers: [],
  exports: []
})
export class HomeLayoutModule {
}
