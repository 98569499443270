import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {HomeLayoutModule} from './modules/home-layout/home-layout.module';
import {HomeModule} from './modules/home/home.module';
import {AppComponent} from './app.component';

import {QuotesSliderService} from './services/quotes-slider.service';
import {LatestPostsService} from './services/latest-posts.service';
import {ProductsService} from './services/products.service';
import {BlogService} from './services/blog.service';
import {CustomersService} from './services/customers.service';
import {PartnersService} from './services/partners.service';
import {PartnersFooterService} from './services/partners-footer.service';
import {CoursesService} from './services/courses.service';
import {EmailService} from './services/email.service';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HomeLayoutModule,
    HomeModule,
    HttpClientModule,
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: [],
  providers: [
    QuotesSliderService,
    LatestPostsService,
    ProductsService,
    BlogService,
    CustomersService,
    PartnersService,
    PartnersFooterService,
    CoursesService,
    EmailService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
