import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import data_json from '../../assets/constants.json';

interface Cliente {
    email: any;
    name: any;
    subject: string;
    phone?: any;
    company?: any;
    message: string;
}

@Injectable()
export class EmailService {

  constructor(public http: HttpClient) {}

  sendActivationEmail(cliente: Cliente) {
    const url = data_json.baseUrl + data_json.routes.sendEmail;
    return this.http.post(url, cliente, {withCredentials: true}).pipe(
      catchError(error => {
        console.error('Error sending activation email:', error);
        return throwError(error);
      })
    );
  }
}
