import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class BlogService {
  publications: any[] = [];

  constructor(private http: HttpClient) {
    // this.load_publications();
  }

  // load_publications() {
  //   this.publications = [
  //     {
  //       'image': './assets/images/blog/imagenes/no-image.webp',
  //       'title': 'Las ventajas de utilizar Android',
  //       'body': '',
  //       'author': 'Edgar Mejía',
  //       'date': '7 de febrero, 2018 - 7:30 a.m',
  //       'hour': '7:30 a.m'
  //     },
  //     {
  //       'image': './assets/images/blog/imagenes/no-image.webp',
  //       'title': 'Las ventajas de utilizar iOS',
  //       'body': '',
  //       'author': 'Paola Reyes',
  //       'date': '7 de febrero, 2018 - 7:30 a.m',
  //       'hour': '7:30 a.m'
  //     },
  //     {
  //       'image': './assets/images/blog/imagenes/no-image.webp',
  //       'title': '¿Cómo repcuperar tus msjs de Whatsapp?',
  //       'body': '',
  //       'author': 'Mauricio Martinez',
  //       'date': '7 de febrero, 2018 - 7:30 a.m',
  //       'hour': '7:30 a.m'
  //     },
  //     {
  //       'image': './assets/images/blog/imagenes/no-image.webp',
  //       'title': 'El Salvador en la informatica',
  //       'body': '',
  //       'author': 'Ana Leiva',
  //       'date': '7 de febrero, 2018 - 7:30 a.m',
  //       'hour': '7:30 a.m'
  //     },
  //     {
  //       'image': './assets/images/blog/imagenes/no-image.webp',
  //       'title': 'Windows 7, ¿El nuevo Windows XP?',
  //       'body': '',
  //       'author': 'Cristina Corvera',
  //       'date': '7 de febrero, 2018 - 7:30 a.m',
  //       'hour': '7:30 a.m'
  //     },
  //     {
  //       'image': './assets/images/blog/imagenes/no-image.webp',
  //       'title': 'PC vs Smartphones',
  //       'body': '',
  //       'author': 'Yakelyn Moscoso',
  //       'date': '7 de febrero, 2018 - 7:30 a.m',
  //       'hour': '7:30 a.m'
  //     }
  //   ];
  // }



  loadPublication(url: string): Observable<any> {
    return this.http.get(url).pipe(
      map(response => {
        return response;
      })
    );
  }
}
