// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,

  staticLandingPictures: 'http://ubuntu-web-dev.creativa.com/CreativaStaticResources/landings/',
  staticBlog: 'http://ubuntu-web-dev.creativa.com/CreativaStaticResources/posts/',

  mailGunUrl: 'https://api.mailgun.net/v3/mail.laplacita.market/messages',
  mailGunKey: '727772865217e274966a7eaeaf15a517-81bd92f8-e95598a0'
};
