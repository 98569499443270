import {Component, HostListener} from '@angular/core';

declare var jQuery: any;
declare var $: any;
declare var velocity: any;

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.css']
})
export class MobileMenuComponent {

  constructor() {
  }

  ngAfterViewInit() {
    let toggleNav = $('.cd-nav-trigger');

    this.layerInit();
    $(window).on('resize', () => {
      window.requestAnimationFrame(this.layerInit);
    });

    toggleNav.on('click', () => {
      if (!toggleNav.hasClass('close-nav')) {
        this.openMenu();
      } else {
        this.closeMenu();
      }
    });

    $('.mob-item').on('click', () => {
      this.closeMenu();
      $('html, body').animate({scrollTop: 0}, 'slow');
    });
  }

  public openMenu() {
    let overlayNav = $('.cd-overlay-nav'),
      overlayContent = $('.cd-overlay-content'),
      navigation = $('.cd-primary-nav'),
      toggleNav = $('.cd-nav-trigger');

    toggleNav.addClass('close-nav');
    overlayNav.children('span').velocity({
      translateZ: 0,
      scaleX: 1,
      scaleY: 1,
    }, 500, 'easeInCubic', () => {
      navigation.addClass('fade-in');
    });
  }

  public closeMenu() {
    let overlayNav = $('.cd-overlay-nav'),
      overlayContent = $('.cd-overlay-content'),
      navigation = $('.cd-primary-nav'),
      toggleNav = $('.cd-nav-trigger');

    toggleNav.removeClass('close-nav');
    overlayContent.children('span').velocity({
      translateZ: 0,
      scaleX: 1,
      scaleY: 1,
    }, 500, 'easeInCubic', () => {
      navigation.removeClass('fade-in');
      overlayNav.children('span').velocity({
        translateZ: 0,
        scaleX: 0,
        scaleY: 0,
      }, 0);
      overlayContent.addClass('is-hidden').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', () => {
        overlayContent.children('span').velocity({
          translateZ: 0,
          scaleX: 0,
          scaleY: 0,
        }, 0, () => {
          overlayContent.removeClass('is-hidden');
        });
      });
      if ($('html').hasClass('no-csstransitions')) {
        overlayContent.children('span').velocity({
          translateZ: 0,
          scaleX: 0,
          scaleY: 0,
        }, 0, () => {
          overlayContent.removeClass('is-hidden');
        });
      }
    });
  }

  public layerInit() {
    let overlayNav = $('.cd-overlay-nav'),
      overlayContent = $('.cd-overlay-content');

    let diameterValue = (Math.sqrt(Math.pow($(window).height(), 2) + Math.pow($(window).width(), 2)) * 2);
    overlayNav.children('span').velocity({
      scaleX: 0,
      scaleY: 0,
      translateZ: 0,
    }, 50).velocity({
      height: diameterValue + 'px',
      width: diameterValue + 'px',
      top: -(diameterValue / 2) + 'px',
      left: -(diameterValue / 2) + 'px',
    }, 0);

    overlayContent.children('span').velocity({
      scaleX: 0,
      scaleY: 0,
      translateZ: 0,
    }, 50).velocity({
      height: diameterValue + 'px',
      width: diameterValue + 'px',
      top: -(diameterValue / 2) + 'px',
      left: -(diameterValue / 2) + 'px',
    }, 0);
  }

}
