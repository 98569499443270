import {Component, OnInit} from '@angular/core';

import {TranslateService} from '@ngx-translate/core';
import {PartnersFooterService} from '../../../../services/partners-footer.service';
import * as AOS from 'aos';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  constructor(
    public translate: TranslateService,
    public _ps: PartnersFooterService
  ) {
  }

  todayDate: Date = new Date();

  ngOnInit() {
    AOS.init();
  }

}
