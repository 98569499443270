/**
 * @author Edgar Mejía
 * 24/02/2021
 *
 * @description Este módulo se usa para no tener que inicializar el ngx-translate en cada modulo
 * que se usa, simplemente se importa este que ya lo esta inicializando.
 */

import {NgModule} from '@angular/core';

import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [],
  imports: [],
  exports: [TranslateModule]
})
export class BaseTranslateModule {
}
