import { FormGroup, AbstractControl } from '@angular/forms';

export class FormValidation {

  static getErrors(form: FormGroup): any {
    let errorsMessages = {};
    for (let [key, control] of Object.entries(form.controls)) {
      errorsMessages[key] = this.evalControl(control.errors);
    }
    return errorsMessages;
  }

  static evalControl(errors): any {
    if (errors) {
      switch (true) {
        case !!errors.required:
          return { msj: 'others.required', length: 0 };
        case !!errors.pattern:
          return { msj: 'others.invalidCharacters', length: 0 };
        case !!errors.minlength:
          return { msj: 'others.minLength', length: errors.minlength.requiredLength };
        default:
          return { msj: 'others.void' };
      }
    }
    return { msj: 'others.void' };
  }
}
