import {Injectable} from '@angular/core';
import data_json  from '../../assets/constants.json'

interface Partner {
  title: string;
  img: string;
}

interface Member {
  title: string;
  img: string;
}
@Injectable()
export class PartnersService {
  partners: Partner[] = data_json.partners;
  memberOf: Member[] = data_json.memberOf;
}
