import { SalesforceModule } from './modules/salesforce/salesforce.module';
import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {HomeLayoutComponent} from './modules/home-layout/home-layout.component';
import {HomeComponent} from './modules/home/home.component';
import { LandingTestingComponent } from './modules/landing-testing/landing-testing.component';

const routes: Routes = [
  { path: "blog", loadChildren: "./modules/v4/v4.module#V4Module" },
  {
    path: "testing",
    component: LandingTestingComponent,
  },
  {
    path: "",
    component: HomeLayoutComponent,
    children: [
      { path: "", component: HomeComponent },

      // Lazy Loading
      {
        path: "about",
        loadChildren: "./modules/about-us/about-us.module#AboutUsModule",
      },
      {
        path: "products/:appNumber/:showButton",
        loadChildren: "./modules/products/products.module#ProductsModule",
      },
      {
        path: "testing-factory",
        loadChildren:
          "./modules/testing-factory/testing-factory.module#TestingFactoryModule",
      },
      {
        path: "consulting",
        loadChildren: "./modules/consulting/consulting.module#ConsultingModule",
      },
      {
        path: "courses",
        loadChildren: "./modules/courses/courses.module#CoursesModule",
      },
      {
        path: "services",
        loadChildren: "./modules/services/services.module#ServicesModule",
      },
      {
        path: "customers",
        loadChildren: "./modules/customers/customers.module#CustomersModule",
      },
      {
        path: "web-apps",
        loadChildren: "./modules/web-apps/web-apps.module#WebAppsModule",
      },
      {
        path: "ecommerce",
        loadChildren: "./modules/ecommerce/ecommerce.module#EcommerceModule",
      },
      {
        path: "mobile-apps",
        loadChildren:
          "./modules/mobile-apps/mobile-apps.module#MobileAppsModule",
      },
      {
        path: "rse/talent",
        loadChildren: "./modules/rse/rse.talent.module#RseTalentModule",
      },
      {
        path: "rse/creakind",
        loadChildren: "./modules/rse/rse.creakind.module#RseCreakindModule",
      },
      {
        path: "university",
        loadChildren: "./modules/university/university.module#UniversityModule",
      },
      {
        path: "landings",
        loadChildren: "./modules/landings/landings.module#LandingsModule",
      },
      {
        path: "blogs",
        loadChildren: "./modules/blogs/blogs.module#BlogsModule",
      },
      {
        path: "salesforce",
        loadChildren: "./modules/salesforce/salesforce.module#SalesforceModule",
      },
      {
        path: "salesforce/form/:formSelected",
        loadChildren:
          "./modules/salesforce/modules/salesforce-form/salesforce-form.module#SalesforceFormModule",
      },
      {
        path: "salesforce/confirmation",
        loadChildren:
          "./modules/salesforce/modules/salesforce-confirmation/salesforce-confirmation.module#SalesforceConfirmationModule",
      },
      { path: "**", redirectTo: "/" },
    ],
  },
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy'})],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule {
}
