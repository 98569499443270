import {Injectable} from '@angular/core';

@Injectable()
export class LatestPostsService {
  lates: any[] = [];

  constructor() {
    this.load_lates('es');
  }

  public load_lates(lang) {
    if (lang == 'es') {
      this.lates = [
        {
          id: 1,
          banner: 'https://scontent.fsal2-1.fna.fbcdn.net/v/t1.0-9/34268612_1928840770512427_' +
            '8551710315073503232_n.webp?_nc_cat=0&oh=1d849d01bc7a168e430bf2b7b6e8521b&oe=5BAF617E',
          title: 'Desarrollo de software con C#', author: 'Creativa Studios',
          date: '07 de junio, 2018', hour: '2:33 p.m',
          body: '<p>C# es un lenguaje de programación orientado a objetosdesarrollado y estandarizado por Microsoft como parte de su plataforma .NET. Este lenguaje ha sido influido principalmente por Java, C++, Eiffel, Modula-3 y Pascal, y al mismo tiempo también ha influido a lenguajes como D, F#, Java 5 o Vala. Sí, ha sido influido por el lenguaje de Oracle, y esa influencia se nota sobre todo en el paradigma funcional (según Java reinventado en su versión 8), aunque todavía no ha conseguido influirle en otros ámbitos (azúcar sintáctico) pero por otra parte esperamos que lo consiga.</p>'
            + '<h3>Ventajas</h3>'
            + '<ul><li><p>Las principales ventajas que presenta el uso C# en comparación con otros lenguajes es su potencia como lenguaje, pero también su flexibilidad. Soporta la mayoría de paradigmas, destacando el paradigma funcional que combinado con el paradigma orientado a objetos hacen del lenguaje uno de los más potentes.</p></li></ul>'
            + '<h3>Desventajas</h3>'
            + '<ul>' +
            '<li>La principal desventaja que puede presentar respecto a Java radica en su portabilidad y en la dificultad que trae un desarrollo completo de un producto software empleando este lenguaje. Java es un lenguaje que presenta una curva de aprendizaje menor que la de lenguajes como C# o Perl. Esta curva de aprendizaje no se debe a la sintaxis del código, ni si quiere a como se programa o los nuevos conceptos que introduce, es una curva más referente a la dificultad que con lleva para un usuario novel realizar una correcta documentación del código o una ausencia de una IDE que sirva como referente en el sector educativo, en Java si tenemos el famoso BlueJ utilizado en tantas universidades.</li>' +
            '<li>Tenemos que tener claro que los programas que nosotros desarrollemos son un producto software que tendrá que estar correctamente documentado y probado, no seria racional ni trabajar dos veces (arreglar bugs) ni desarrollar un software que por su mala documentación no lo haga mantenible (debido a que nadie entienda su documentación). Aunque sea cierto que C# si incorpora herramientas de documentación del código estas herramientas no son usables ni genéricas ni tampoco están realmente integradas con el lenguaje, ni tampoco con su entorno.</li>' +
            '<li>Es importante explicar que aunque estas desventajas no formen parte del lenguaje si que forman parte de su entorno y lo que hacen es que el desarrollo de un productos software, utilizando el lenguaje C#, sobre la plataforma .NET o Mono tenga defectos en el producto final, que aunque no este relacionado de forma directa con la programación, con picar código, si que condiciona que el producto desarrollado no sea tan mantenible como si tuviese una correcta documentación. Es por ello que la curva de aprendizaje, en un lenguaje que mantiene casi toda la sintaxis igual que Java, se ve incrementada por la carencia de un sistema de documentación universal. En este punto, también se podrían añadir otros aspectos como la universalización de una guía de estilo para el lenguaje, es verdad que existen distintos movimientos (entre los que se encuentra Microsoft) pero todavía es muy habitual ver un programa en C# empleando una guía de estilo de Java (por ejemplo con los nombres de los métodos en minúscula). Otro punto importante es que actualmente los entornos por excelencia (Mono y .NET) no utilizan el mismo framework para las pruebas y esto quizás si que pueda perjudicarnos si trabajamos en un equipo de desarrollo con otras 100 personas.</li>' +
            '</ul>'
        },
        {
          id: 2,
          banner: 'https://scontent.fsal2-1.fna.fbcdn.net/v/t1.0-9/33617045_1921407107922460_2' +
            '475472746864181248_o.webp?_nc_cat=0&oh=e066c893f6ee19bfbecd259d318ad08f&oe=5BE01CF6',
          title: 'Programador PHP: 5 consejos para ser el mejor', author: 'Creativa Studios',
          date: '25 de mayo, 2018', hour: '9:47 a.m',
          body: '<p>PHP es probablemente el lenguaje de desarrollo web más popular en estos momentos. Al menos 20 millones de dominios usar PHP y es el idioma utilizado en los principales sitios como Wikipedia y Facebook, así como en algunos de los mayores proyectos de código abierto del mundo, como WordPress y Drupal.' +
            'Estos son los 5 consejos que te llevarán a ser el mejor programador PHP</p>'
            + '<h3>1. Programación Orientada a Objetos</h3>'
            + '<p>La programación orientada a objetos es esencialmente un método de programación con el uso de clases u objetos. Los objetos son esencialmente clases que recogen un montón de funciones juntos y se “envuelven” en un contenedor el cual puede ser reutilizado una y otra vez sin la necesidad de volver a escribir la funcionalidad o procedimientos cada vez que tengas que hacer algo. En síntesis elimina la necesidad de repetición de código y realiza las tareas básicas de la producción de manera muy sencilla y eficaz.</p>'
            + '<h3>2. Evite todo lo que que terminen en  _once ()</h3>'
            + '<p>Include_once () y require_once () son extremadamente negativos para los recursos del servidor. No hay nada que podamos hacer al respecto, es la forma en PHP está configurado. Sólo recuerde que estas cosas sobrecargan los recursos del servidor, por tanto si quieres plantear un código optimizado y correctamente desarrollado está claro que debes evitar este comando.</p>'
            + '<h3>3. Activa tu informe de errores</h3>'
            + '<p>Lo primero que debes hacer al iniciar un nuevo proyecto es activar tu informe de errores y  únicamente debes ponerlo en OFF diez segundos antes de pasar al modo de producción. Haz esto con cada proyecto que desarrolles y vive la satisfacción de ejecutar un proyecto en producción SIN ERRORES, o en el peor de los casos, con un bajo indicador de errores.</p>'
            + '<h3>4. Utiliza un Framework</h3>'
            + '<p>A la hora de programar muchos programadores dudan en utilizar un framework porque inicialmente les parece que sus tiempos podrían ralentizarse, además si estás acostumbrando al PHP de toda la vida donde ya tienes tu método y orden de producción, la resistencia al cambio brillará con notoriedad, no obstante lo que muchos descubren que al utilizar ZEND, YII, SIMFONY , por ejemplo, a la larga el orden del código, la reutilización de librerías y código en general estará mucho más depurado y optimizado y te permitirá avanzar más rápidamente y con mayor fiabilidad en tus desarrollos, eso sí, nadie ha dicho que será fácil que cambies tu forma de programar.</p>'
            + '<h3>5. Usa POST no GET</h3>'
            + '<p>Ok, partamos de la base de que esto no siempre es posible, pero cuando no sea realmente necesario no utilices GET, utiliza sin duda alguna POST. La razón es simple – GET es fácil de imitar, lo único que tengo que hacer es añadir algo a mi barra de direcciones y puedo hackear tu proyecto. Obviamente GET es la manera fácil de hacer la paginación y enlaces permanentes, pero cuando se utiliza el envío de formularios especialmente, quédate con el POST que es más seguro.</p>'
        },
        {
          id: 3,
          banner: 'https://scontent.fsal2-1.fna.fbcdn.net/v/t1.0-9/32650043_1911417022254802' +
            '_1423585924079419392_n.webp?_nc_cat=0&oh=675819209eeeecec47fc39ebb0930b8f&oe=5BA03D1E',
          title: 'Desarrollo de aplicaciones para iOS', author: 'Creativa Studios',
          date: '02 de abril, 2018', hour: '1:10 p.m',
          body: '<p>Hay muchos aros a través de los cuales saltar en lo que se refiere al desarrollo de aplicaciones para Apple iOS. Primero, los desarrolladores tienen que aprender Xcode, el entorno de  desarrollo integrado para iOS. Luego, deben navegar por un laberinto de  certificados, licencias y perfiles, todos los cuales vienen con  diferentes fechas de vencimiento y reglas de renovación. Si son capaces  de pasar al otro lado, todavía tienen que averiguar la mejor manera de  entregar las aplicaciones a los usuarios.</p>'
            + '<h4>¿Cómo funciona Xcode?</h4>'
            + '<p>Xcode  se ejecuta en Mac y tiene todo lo que los desarrolladores necesitan  para construir aplicaciones para OS X, Apple Watch, web e iOS. Desde  2014, Xcode utiliza Swift como su lenguaje de codificación en lugar de  Objective-C, que es un lenguaje difícil de aprender y de hacer la  transición para los desarrolladores. Con iOS 9, Apple presentó Swift 2.0.  Los desarrolladores pueden poner su código en un repositorio Git y  compartirlo con otros grupos para colaborar en el proceso de desarrollo.\n' +
            'En lugar de rechazar iOS, TI debe aceptarlo y aprender a crear  aplicaciones para el sistema operativo ellos sí mismos, en lugar de  contratar a desarrolladores caros de iOS.\n' +
            'El enfoque de modelo-vista-controlador de Xcode hace que la gestión  del código en cada aplicación sea fácil con herramientas como el  Interface Builder, que permite a los desarrolladores arrastrar y soltar distintos controles visuales  en el código de la aplicación. AutoLayout ayuda a los desarrolladores a  controlar la presentación de la aplicación, en función del tamaño de la  pantalla del usuario. Con Storyboard, los desarrolladores pueden ver  cómo luce cada pantalla de la aplicación, y el Preview Mode ofrece un  adelanto de cómo se verá la aplicación cuando esté lista.\n' +
            'Xcode es de uso gratuito, pero si los desarrolladores quieren  publicar sus aplicaciones en iTunes o en la tienda de Mac OS X, tienen  que pagar 99 dólares por una licencia anual de desarrollador.</p>'
            + '<h4>¿Qué pasa con el proceso de publicación de aplicaciones?</h4>'
            + '<p>Incluso si los desarrolladores saben cómo usar Xcode, publicar una  aplicación para iOS no es tarea sencilla. El primer paso es registrarse  en el Programa de Desarrollador Empresarial iOS (iOS Developer Enterprise Program),  que cuesta 299 dólares por año de membresía. Una vez que una  organización se inscribe, cada uno de sus desarrolladores tiene un  perfil de aprovisionamiento, que pueden utilizar para firmar  aplicaciones de iOS con un certificado de empresa  y elegir en qué dispositivos se ejecuta la aplicación. Cada certificado  dura un año calendario. Como resultado, los desarrolladores tienen que  volver a publicar sus aplicaciones cada año con un nuevo certificado.\n' +
            'La diversión no se detiene allí. El desarrollador también tiene que  enviar una solicitud de firma de certificado al Portal de  Aprovisionamiento iOS (iOS Provisioning Portal). Una vez que la  solicitud de firma de certificado es aprobada, el desarrollador puede  firmar la aplicación para un número ilimitado de dispositivos. Estos  certificados duran tres años.</p>'
            + '<h4>¿Qué opciones existen para la entrega de aplicaciones a los usuarios?</h4>'
            + '<p>Una vez que los desarrolladores pasan a través del laberinto de  certificados del desarrollo de aplicaciones iOS, pueden utilizar la App  Store de Apple para entregar las aplicaciones a los usuarios. El  Programa de Compras por Volumen permite a las organizaciones entregar  aplicaciones a los dispositivos de los usuarios sin asociarlos con un ID  de Apple.</p><br></p>' +
            'Existen alternativas a la tienda de aplicaciones. Los  administradores pueden entregar sus aplicaciones de iOS a los usuarios  por el aire desde un servidor web, sin tener que conectar un dispositivo  al host Utilidad de Configuración del iOS. Los usuarios simplemente  hacen clic en un enlace para descargar las aplicaciones, y TI puede proteger las aplicaciones con encriptación para que solo los usuarios autenticados pueden accedar a ellas.</p></br><p>' +
            'Además, los administradores pueden entregar aplicaciones a través de  iTunes, pero este método solo es realista cuando los usuarios conectan  sus dispositivos iOS a una PC o Mac con ese software. Otro método que  requiere una conexión a Mac o PC es Apple Configurator, que funciona mejor cuando los administradores están configurando alrededor de 30 dispositivos a la vez.</p>'
        }
      ];
    } else if (lang === 'en') {
      this.lates = [
        {
          id: 1,
          banner: 'https://scontent.fsal2-1.fna.fbcdn.net/v/t1.0-9/34268612_1928840770512427_' +
            '8551710315073503232_n.webp?_nc_cat=0&oh=1d849d01bc7a168e430bf2b7b6e8521b&oe=5BAF617E',
          title: 'Desarrollo de software con C#', author: 'Creativa Studios',
          date: '07 de junio, 2018', hour: '2:33 p.m',
          body: '<p> C # is an object-oriented programming language developed and standardized by Microsoft as part of its .NET platform. This language has been influenced mainly by Java, C ++, Eiffel, Modula-3 and Pascal, and at the same time it has also influenced languages ​​like D, F #, Java 5 or Vala. Yes, it has been influenced by the Oracle language, and this influence is especially noticeable in the functional paradigm (according to Java reinvented in its version 8), although it has not yet managed to influence it in other areas (syntactic sugar) but on the other hand we expect I got it. </ p> '
            + '<h3> Advantages </ h3>'
            + '<ul> <li> <p> The main advantages of using C # compared to other languages ​​is its power as a language, but also its flexibility. It supports most paradigms, highlighting the functional paradigm that combined with the object-oriented paradigm make language one of the most powerful. </ P> </ li> </ ul> '
            + '<h3> Disadvantages </ h3>'
            + '<ul>' +
            '<li> The main disadvantage that can arise with respect to Java lies in its portability and in the difficulty that a complete development of a software product brings with this language. Java is a language that has a lower learning curve than languages ​​like C # or Perl. This learning curve is not due to the syntax of the code, nor if it wants to as it is programmed or the new concepts that it introduces, it is a curve more referring to the difficulty that it takes for a novel user to carry out a correct documentation of the code or a absence of an IDE that serves as a reference in the educational sector, in Java if we have the famous BlueJ used in so many universities. </ li> ' +
            '<li> We have to be clear that the programs we develop are a software product that will have to be properly documented and tested, it would not be rational or work twice (fix bugs) or develop a software that due to its bad documentation does not do it maintainable (because no one understands your documentation). Although it is true that C # does incorporate code documentation tools, these tools are not usable or generic nor are they really integrated with the language, nor with their environment. </ Li> ' +
            '<li> It is important to explain that although these disadvantages are not part of the language if they are not part of their environment and what they do is that the development of a software software, using the C # language, on the .NET or Mono platform defects in the final product, that although it is not related to the direct form with the programming, with pillar code, if the product can not be maintained as if it had a correct documentation. That is why the learning curve, in a language that maintains almost all the syntax that Java, has been increased by the lack of a universal documentation system. At this point, you can also access other methods such as the universalization of a style guide for the language, that is to say that there are very common movements among those that can be found in Microsoft. Java style (for example, with the names of the methods in lowercase). Another important point is that currently the environments par excellence (Mono and .NET) do not use the same framework for testing and this maybe if it can harm us if you work as a team with 100 other people. </ li>' +
            '</ul>'
        },
        {
          id: 2,
          banner: 'https://scontent.fsal2-1.fna.fbcdn.net/v/t1.0-9/33617045_1921407107922460_2' +
            '475472746864181248_o.webp?_nc_cat=0&oh=e066c893f6ee19bfbecd259d318ad08f&oe=5BE01CF6',
          title: 'Programador PHP: 5 consejos para ser el mejor', author: 'Creativa Studios',
          date: '25 de mayo, 2018', hour: '9:47 a.m',
          body: '<p>PHP es probablemente el lenguaje de desarrollo web más popular en estos momentos. Al menos 20 millones de dominios usar PHP y es el idioma utilizado en los principales sitios como Wikipedia y Facebook, así como en algunos de los mayores proyectos de código abierto del mundo, como WordPress y Drupal.' +
            'Estos son los 5 consejos que te llevarán a ser el mejor programador PHP</p>'
            + '<h3>1. Programación Orientada a Objetos</h3>'
            + '<p>La programación orientada a objetos es esencialmente un método de programación con el uso de clases u objetos. Los objetos son esencialmente clases que recogen un montón de funciones juntos y se “envuelven” en un contenedor el cual puede ser reutilizado una y otra vez sin la necesidad de volver a escribir la funcionalidad o procedimientos cada vez que tengas que hacer algo. En síntesis elimina la necesidad de repetición de código y realiza las tareas básicas de la producción de manera muy sencilla y eficaz.</p>'
            + '<h3>2. Evite todo lo que que terminen en  _once ()</h3>'
            + '<p>Include_once () y require_once () son extremadamente negativos para los recursos del servidor. No hay nada que podamos hacer al respecto, es la forma en PHP está configurado. Sólo recuerde que estas cosas sobrecargan los recursos del servidor, por tanto si quieres plantear un código optimizado y correctamente desarrollado está claro que debes evitar este comando.</p>'
            + '<h3>3. Activa tu informe de errores</h3>'
            + '<p>Lo primero que debes hacer al iniciar un nuevo proyecto es activar tu informe de errores y  únicamente debes ponerlo en OFF diez segundos antes de pasar al modo de producción. Haz esto con cada proyecto que desarrolles y vive la satisfacción de ejecutar un proyecto en producción SIN ERRORES, o en el peor de los casos, con un bajo indicador de errores.</p>'
            + '<h3>4. Utiliza un Framework</h3>'
            + '<p>A la hora de programar muchos programadores dudan en utilizar un framework porque inicialmente les parece que sus tiempos podrían ralentizarse, además si estás acostumbrando al PHP de toda la vida donde ya tienes tu método y orden de producción, la resistencia al cambio brillará con notoriedad, no obstante lo que muchos descubren que al utilizar ZEND, YII, SIMFONY , por ejemplo, a la larga el orden del código, la reutilización de librerías y código en general estará mucho más depurado y optimizado y te permitirá avanzar más rápidamente y con mayor fiabilidad en tus desarrollos, eso sí, nadie ha dicho que será fácil que cambies tu forma de programar.</p>'
            + '<h3>5. Usa POST no GET</h3>'
            + '<p>Ok, partamos de la base de que esto no siempre es posible, pero cuando no sea realmente necesario no utilices GET, utiliza sin duda alguna POST. La razón es simple – GET es fácil de imitar, lo único que tengo que hacer es añadir algo a mi barra de direcciones y puedo hackear tu proyecto. Obviamente GET es la manera fácil de hacer la paginación y enlaces permanentes, pero cuando se utiliza el envío de formularios especialmente, quédate con el POST que es más seguro.</p>'
        },
        {
          id: 3,
          banner: 'https://scontent.fsal2-1.fna.fbcdn.net/v/t1.0-9/32650043_1911417022254802' +
            '_1423585924079419392_n.webp?_nc_cat=0&oh=675819209eeeecec47fc39ebb0930b8f&oe=5BA03D1E',
          title: 'Desarrollo de aplicaciones para iOS', author: 'Creativa Studios',
          date: '02 de abril, 2018', hour: '1:10 p.m',
          body: '<p>Hay muchos aros a través de los cuales saltar en lo que se refiere al desarrollo de aplicaciones para Apple iOS. Primero, los desarrolladores tienen que aprender Xcode, el entorno de  desarrollo integrado para iOS. Luego, deben navegar por un laberinto de  certificados, licencias y perfiles, todos los cuales vienen con  diferentes fechas de vencimiento y reglas de renovación. Si son capaces  de pasar al otro lado, todavía tienen que averiguar la mejor manera de  entregar las aplicaciones a los usuarios.</p>'
            + '<h4>¿Cómo funciona Xcode?</h4>'
            + '<p>Xcode  se ejecuta en Mac y tiene todo lo que los desarrolladores necesitan  para construir aplicaciones para OS X, Apple Watch, web e iOS. Desde  2014, Xcode utiliza Swift como su lenguaje de codificación en lugar de  Objective-C, que es un lenguaje difícil de aprender y de hacer la  transición para los desarrolladores. Con iOS 9, Apple presentó Swift 2.0.  Los desarrolladores pueden poner su código en un repositorio Git y  compartirlo con otros grupos para colaborar en el proceso de desarrollo.\n' +
            'En lugar de rechazar iOS, TI debe aceptarlo y aprender a crear  aplicaciones para el sistema operativo ellos sí mismos, en lugar de  contratar a desarrolladores caros de iOS.\n' +
            'El enfoque de modelo-vista-controlador de Xcode hace que la gestión  del código en cada aplicación sea fácil con herramientas como el  Interface Builder, que permite a los desarrolladores arrastrar y soltar distintos controles visuales  en el código de la aplicación. AutoLayout ayuda a los desarrolladores a  controlar la presentación de la aplicación, en función del tamaño de la  pantalla del usuario. Con Storyboard, los desarrolladores pueden ver  cómo luce cada pantalla de la aplicación, y el Preview Mode ofrece un  adelanto de cómo se verá la aplicación cuando esté lista.\n' +
            'Xcode es de uso gratuito, pero si los desarrolladores quieren  publicar sus aplicaciones en iTunes o en la tienda de Mac OS X, tienen  que pagar 99 dólares por una licencia anual de desarrollador.</p>'
            + '<h4>¿Qué pasa con el proceso de publicación de aplicaciones?</h4>'
            + '<p>Incluso si los desarrolladores saben cómo usar Xcode, publicar una  aplicación para iOS no es tarea sencilla. El primer paso es registrarse  en el Programa de Desarrollador Empresarial iOS (iOS Developer Enterprise Program),  que cuesta 299 dólares por año de membresía. Una vez que una  organización se inscribe, cada uno de sus desarrolladores tiene un  perfil de aprovisionamiento, que pueden utilizar para firmar  aplicaciones de iOS con un certificado de empresa  y elegir en qué dispositivos se ejecuta la aplicación. Cada certificado  dura un año calendario. Como resultado, los desarrolladores tienen que  volver a publicar sus aplicaciones cada año con un nuevo certificado.\n' +
            'La diversión no se detiene allí. El desarrollador también tiene que  enviar una solicitud de firma de certificado al Portal de  Aprovisionamiento iOS (iOS Provisioning Portal). Una vez que la  solicitud de firma de certificado es aprobada, el desarrollador puede  firmar la aplicación para un número ilimitado de dispositivos. Estos  certificados duran tres años.</p>'
            + '<h4>¿Qué opciones existen para la entrega de aplicaciones a los usuarios?</h4>'
            + '<p>Una vez que los desarrolladores pasan a través del laberinto de  certificados del desarrollo de aplicaciones iOS, pueden utilizar la App  Store de Apple para entregar las aplicaciones a los usuarios. El  Programa de Compras por Volumen permite a las organizaciones entregar  aplicaciones a los dispositivos de los usuarios sin asociarlos con un ID  de Apple.</p><br></p>' +
            'Existen alternativas a la tienda de aplicaciones. Los  administradores pueden entregar sus aplicaciones de iOS a los usuarios  por el aire desde un servidor web, sin tener que conectar un dispositivo  al host Utilidad de Configuración del iOS. Los usuarios simplemente  hacen clic en un enlace para descargar las aplicaciones, y TI puede proteger las aplicaciones con encriptación para que solo los usuarios autenticados pueden accedar a ellas.</p></br><p>' +
            'Además, los administradores pueden entregar aplicaciones a través de  iTunes, pero este método solo es realista cuando los usuarios conectan  sus dispositivos iOS a una PC o Mac con ese software. Otro método que  requiere una conexión a Mac o PC es Apple Configurator, que funciona mejor cuando los administradores están configurando alrededor de 30 dispositivos a la vez.</p>'
        }
      ];
    }

  }
}
