import {Injectable} from '@angular/core';

@Injectable()
export class ProductsService {
  products = [];
  appNumber: string;

  constructor() { }

  public load_products(lang) {

    if (lang === 'es') {
      this.products = [
        {
          id: 1,
          image: './assets/images/productos/productos-img/LAB-04.webp',
          title: 'Fulea',
          description: 'Un servicio en la nube pensado para beneficiar a usuarios de dispositivos Android e iOS y acercarlos a los comercios relacionados con gasolineras, combustibles, transporte y mantenimiento sirviendo como herramienta de fidelización, promoción y publicidad de los servicios y productos de cada afiliado. Fulea ayuda a los usuarios a encontrar cerca de ellos las gasolineras que mejor les convengan, pueden verlas en un mapa, trazar una ruta o verlas en una lista ordenadas por cercanía o por precio, permitiendo elegir el tipo de combustible y tipo de servicio que requieren.',
          chars: [
            'Gasolineras y establecimientos en un mapa.',
            'Chekins, reportes de precios, alertas de tráfico, puntos de actividades e interacción en comunidad.',
            'Control de consumo por vehículo.',
            'Notificación de precios oficiales.',
            'Números de emergencia.'
          ],
          androidLink: 'https://play.google.com/store/apps/details?id=com.fulea.app',
          appleLink: '',
          videourl: 'https://www.youtube.com/watch?v=Wh5od7pLO04'
        },
        {
          id: 2,
          image: './assets/images/productos/productos-img/HT2.webp',
          title: 'Health Tourism',
          description: 'La primera app para turismo médico de El Salvador, Mexico, Costa Rica, Ecuador y muchos otros países. Encuentra los mejores doctores, especialistas, clínicas, hospitales, laboratorios y clínicas ópticas de la región y disfruta todos sus beneficios; vanguardiamédica, tratamientos, procedimientos de alta calidad,especialistas internacionales y precios competitivos.',
          chars: [
            'Health Tourism te proporciona la información de primer contacto para ubicar rápidamente a tu doctor o establecimiento médico.',
            'Encuentras clínicas, hospitales, ópticas, laboratorios con su información mas relevante.',
            'Infórmate de los tratamientos realizados en América Latina; solicita más detalle de cómo estos tratamientos te pueden ayudar y comparte con tus contactos.',
            'Encuentra la ubicación de tus doctores, sus números de teléfono, correos electrónicos y lleva siempre su información a la mano.',
            'Realiza las búsquedas por nombre de doctor, especialidad o servicios de una forma fácil y rápida.'
          ],
          androidLink: 'https://play.google.com/store/apps/details?id=com.creativa.medical.locator',
          appleLink: 'https://itunes.apple.com/tj/app/health-tourism/id957342956?mt=8',
          videourl: 'https://www.youtube.com/watch?v=1FbKXPuxkWo'
        },
        /*       {
                id: 3,
                image: './assets/images/productos/productos-img/JCS2.webp',
                title: 'APP 3',
                char1: 'Pro quis mutat omittantur et.',
                char2: 'Vis te paulo prompta molestiae.',
                char3: 'Ea everti deterruisset sea.',
                char4: 'Est velit nullam ut.',
                char5: 'Pri illum lucilius id.',
                androidLink: '',
                appleLink: ''
              }, */
        {
          id: 3,
          image: './assets/images/productos/productos-img/SS2.webp',
          title: 'Smart Stadium',
          description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait',
          chars: [
            'Comida rápida a tiempo.',
            'Puedes recogerla cuando esté lista o recibirla desde el lugar que te encuentres.',
            'Evita hacer largas filas para ordenar.',
            'Ideal para el sector de estadios con la posibilidad de integrar a restaurantes.'
          ],
          androidLink: '',
          appleLink: '',
          videourl: ''
        },
        {
          id: 4,
          image: './assets/images/productos/productos-img/LAB-09.webp',
          title: 'Visible Outsource',
          description: 'Visible Outsorce(VO), es una suite dedicada a procesos de head hunting tiene la habilidad de crear y gestionar ofertas de trabajo y capturar de una manera homogénea la información de los aplicantes, y gestionar los perfiles seleccionados..',
          chars: [
            'Servir de ayuda a diferentes industrias.',
            'Ofertar vacantes de trabajo.',
            'Captura homogénea de la información.'
          ],
          androidLink: 'https://play.google.com/store/apps/details?id=com.creativa.talentos',
          appleLink: 'https://apps.apple.com/sv/app/visible-outsource/id1196292847',
          videourl: ''
        },
        {
          id: 5,
          image: './assets/images/productos/productos-img/LAB-08.webp',
          title: 'Sircs',
          description: 'Comunicación instantánea, desde cualquier lugar, ideal para trabajos colaborativos, compartiendo documentos, archivos, audios, videos o PDF’s. Apoyada en seguridad de alto nivel de encriptación..',
          chars: [
            'Perfiles de usuario.',
            'Ajustes de seguridad a nivel de usuario.',
            'Almacenamiento seguro.',
            'Contactos.',
            'Grupos de trabajo.',
            'Configuración de marcos.',
            'Imagen corporativa',
            'Servidor con autenticación de usuario.',
            'Administración de Usuarios.',
            'Administración de chats (claves de cifrado).',
            'Uso exclusivo.',
            'Almacenamiento local.'
          ],
          androidLink: '',
          appleLink: '',
          videourl: 'https://www.youtube.com/watch?v=WCT-ZzJilbM'
        },
        {
          id: 6,
          image: './assets/images/productos/productos-img/LAB-07.webp',
          title: 'Collect App',
          description: 'Especializada en incrementar la eficiencia y reducir el impacto de los impagos en las cuentas de resultados. Nuestro objetivo principal es ayudar a las empresas a reducir sus índices de morosidad, mejorar su liquidez e incrementar la rentabilidad de sus operaciones; optimizar y garantizar la función de los gestores de cobros. Basado en geolocalización, desde una simple acción de buscar lugares cercanos hasta realizar un rastreo constante, regidos por un horario y con generación de alertas según los datos ingresados por los gestores desde la app móvil. Se encuentra compuesta por una suite que incluye elementos web, aplicación móvil e incluso bots analizadores de ubicaciones para tareas automáticas.',
          chars: [
            'Información oportuna de localización y desplazamientos.',
            'Gestión off/on line usando smartphones.',
            'Self service: facilidad y autonomía en la gestión.',
            'Programación automática y manual.',
            'Acciones en Bloque y uso de Filtros.',
            'Programación de acuerdo a variables.'
          ],
          androidLink: '',
          appleLink: '',
          videourl: 'https://www.youtube.com/watch?v=N5G7nS7b-L4'
        },
        {
          id: 7,
          image: './assets/images/productos/productos-img/LAB-10.webp',
          title: 'EuphoRHia',
          description: 'El trabajo de recursos humanos es fundamental para una empresa, y este aumenta a medida la empresa crece Euforh.ia le ayuda a su empresa al manejar solicitudes, formularios, permisos, etc, de forma electrónica para evitar todo el papeleo. Además tiene el componente de pagos a empleados por planilla.',
          chars: [
            'Registro de candidatos y formulario de aplicación a ofertas de trabajo.',
            'Conversión de candidatos en empleados.',
            'Manejo de contratos digitales e imprimibles.',
            'Gestión de permisos, horas extras, vacaciones, incapacidades, capacitaciones, inducciones, etc.',
            'Habilidad para manejar consorcios de empresas',
            'Configuraciones por empresa.',
            'Permisos por pantallas, asignable a empresas y usuarios.',
            'Gestión de planillas quincenales y mensuales.',
            'Cálculos automáticos de planillas.',
            'Múltiples planillas para servicios profesionales o nómina, distintas periodicidades de pago o por departamento, todo completamente configurable.',
            'Gestión de descuentos recurrentes, de un solo momento, incrementos, bonos, comisiones.',
            'Generación de archivos para pagos en bancos, ISSS, AFP, etc.',
            'Recálculos de renta y reporte anual F910.',
            'Envío de boletas de pago por correo electrónico.',
            'Generación y cálculo de finiquitos.',
            'Cálculo de indemnización por despido o por renuncia voluntaria.'
          ],
          androidLink: '',
          appleLink: '',
          videourl: 'https://www.youtube.com/watch?v=MzXTL-eKaEY'
        }
      ];
    } else if (lang === 'en') {
      this.products = [
        {
          id: 1,
          image: './assets/images/productos/productos-img/LAB-04.webp',
          title: 'Fulea',
          description: 'Cloud service developed to help to users from Android and iOS and get them to the partners registered into the app, most of them related to gas stations, transport, car workshops, working as a great tool to get an engagement with clients. Fulea helps to users to find the gas stations and any partner around their route, they can see them in the map, get the route or check the list of them, filter them by price and preferences. Also, they can get ads and notifications from the partners of the app.',
          chars: [
            'Gas stations and establishments on a map.',
            'Chekins, price reports, traffic alerts, activity points and community interaction.',
            'Control of consumption by vehicle.',
            'Notification of official prices.',
            'Emergency numbers.'
          ],
          androidLink: 'https://play.google.com/store/apps/details?id=com.fulea.app',
          appleLink: '',
          videourl: ''
        },
        {
          id: 2,
          image: './assets/images/productos/productos-img/HT2.webp',
          title: 'Health Tourism',
          description: 'The first app for medic tourism in El Salvador, Mexico, Costa Rica, Ecuador, and many other countries. Find the best doctors, specialists, clinics, hospitals and ophthalmologist clinics, treatments, high-quality medical procedures, international specialist and competitive prices..',
          chars: [
            'Health Tourism gives the contact information to locate in an efficient wat to a doctor or any medical service.',
            'Find clinics, hospitals, labs, etc.',
            'Get informed about the treatments in Latinamerica, get more details and share them with your contacts.',
            'Find your doctor location, phone number, email and have this information on-premise anytime.',
            'Perform a search by doctor name, speciality or service in a faster and efficient way.'
          ],
          androidLink: 'https://play.google.com/store/apps/details?id=com.creativa.medical.locator',
          appleLink: 'https://itunes.apple.com/tj/app/health-tourism/id957342956?mt=8',
          videourl: ''
        },
        /*       {
                id: 3,
                image: './assets/images/productos/productos-img/JCS2.webp',
                title: 'APP 3',
                char1: 'Pro quis mutat omittantur et.',
                char2: 'Vis te paulo prompta molestiae.',
                char3: 'Ea everti deterruisset sea.',
                char4: 'Est velit nullam ut.',
                char5: 'Pri illum lucilius id.',
                androidLink: '',
                appleLink: ''
              }, */
        {
          id: 3,
          image: './assets/images/productos/productos-img/SS2.webp',
          title: 'Smart Stadium',
          description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait',
          chars: [
            'Fast food on time.',
            'You can pick it up when it is ready or receive it from the place you are.',
            'Avoid making long lines to order.',
            'Ideal for the stadium sector with the possibility of integrating restaurants.'
          ],
          androidLink: '',
          appleLink: '',
          videourl: ''
        },
        {
          id: 4,
          image: './assets/images/productos/productos-img/LAB-09.webp',
          title: 'Visible Outsource',
          description: 'Visible Outsorce (VO), is a suite dedicated to head hunting processes has the ability to create and manage job offers and capture in a homogeneous way the information of the applicants, and manage the selected profiles.',
          chars: [
            'Serve as help to different industries.',
            'Offer job vacancies.',
            'Homogeneous capture of information.'
          ],
          androidLink: 'https://play.google.com/store/apps/details?id=com.creativa.talentos',
          appleLink: 'https://apps.apple.com/sv/app/visible-outsource/id1196292847',
          videourl: ''
        },
        {
          id: 5,
          image: './assets/images/productos/productos-img/LAB-08.webp',
          title: 'Sircs',
          description: 'Instant communication anywhere, ideal for collaborative work, sharing documents, files, audios or videos; With high-level encryption security..',
          chars: [
            'User Profiles.',
            'Security setup.',
            'Safe storage.',
            'Contacts.',
            'Workgroups.',
            'Skin configurations.',
            'Corporative logo',
            'Authentication server.',
            'Users administration.',
            'Chat management (Cipher keys).',
            'Exclusive per company.',
            'Local storage.'
          ],
          androidLink: '',
          appleLink: '',
          videourl: ''
        },
        {
          id: 6,
          image: './assets/images/productos/productos-img/LAB-07.webp',
          title: 'Collect App',
          description: 'Specialized in increment the efficiency and reduce the impact of non-payments in results. Our main objective is to help companies to reduce the late payments, improve their liquidity and raise the rentability in their operations; optimize and guarantee the collectors development. Based on geolocation services, looking for a simple location or perform a constant tracking, ruled by a schedule, alert generation based on the data from the collectors using the mobile application. This suite is composed of web and mobile applications and a robot which is in charge to analyze the locations to have some automated task.',
          chars: [
            'Timely location and travel information.',
            'Offline and online management through mobile application.',
            'Self-service.',
            'Automatic and manual routes setup.',
            'Bulk actions and filters.',
            'Schedule setup based on configuration.'
          ],
          androidLink: '',
          appleLink: '',
          videourl: ''
        },
        {
          id: 7,
          image: './assets/images/productos/productos-img/LAB-10.webp',
          title: 'EuphoRHia',
          description: 'Human Resources it\'s one of the most important areas in a company and the work grows during the company expands; thinking about this we present Euforh.ia, which helps to your company to manage requests, forms, work permit, etc in a digital way to avoid the paperwork. So it\'s also a way to save the planet; also it provides the payments component. Además tiene el componente de pagos a empleados por planilla.',
          chars: [
            'Job applications management.',
            'Forms for job applications management.',
            'The conversion process from Job applicants to employees.',
            'Management for contracts.',
            'Work permit, extra hours, holidays, part-time off, training, etc; management.',
            'Able to manage Bussines Consortium.',
            'Configurations per company.',
            'Security permissions are assignable to companies and users.',
            'Management of payments sheets biweekly and monthly.',
            'Autocalculus of payments sheets.',
            'Setup for multiple payment sheets, periods and departments.',
            'Recurrent and one-time discounts management.',
            'Increments, bonus and commissions management.',
            'Bank payments and taxes file generation.',
            'Payment notifications by email.',
            'Settlement generations.',
            'Compensation calculus for dismissals.'
          ],
          androidLink: '',
          appleLink: '',
          videourl: ''
        }
      ];
    } else if (lang === 'fr') {
      this.products = [
        {
          id: 1,
          image: './assets/images/productos/productos-img/LAB-04.webp',
          title: 'Fulea',
          description: 'Service en Nouge développé pour aider les utilisateurs d’Android et iOS et les mettre en contact avec les partenaires enregistrés dans l’application, la plupart d’entre eux liés aux stations-service, aux transports, aux ateliers de voiture, fonctionnant comme un excellent outil pour obtenir un engagement des clients. Fulea aide les utilisateurs à trouver les stations-service et tous les partenaires proches de leur route. Ils peuvent les voir sur la carte, obtenir la route ou consulter la liste, les filtrer par prix et préférences. En plus, ils peuvent obtenir des annonces et des notifications des partenaires de notre application.',
          chars: [
            'Stations-service et établissements sur une carte.',
            'Vérifications, rapports de prix, alertes de trafic, points d’activité et interaction avec la communauté.',
            'Contrôle de consommation par véhicule.',
            'Notification des prix officiels.',
            'Numéros d’urgence.'
          ],
          androidLink: 'https://play.google.com/store/apps/details?id=com.fulea.app',
          appleLink: '',
          videourl: ''
        },
        {
          id: 2,
          image: './assets/images/productos/productos-img/HT2.webp',
          title: 'Health Tourism', /* If this is the name of the app, it should be restored back to "Health Tourism" */
          description: 'La première application pour le tourisme médical en El Salvador, Mexique, Costa Rica, Équateur et le prémier dans nombreux d’autres pays. Trouvez les meilleurs médecins, spécialistes, cliniques, hôpitaux et cliniques ophtalmologiques, traitements, procédures médicales de haute qualité, spécialistes internationaux et prix compétitifs.',
          chars: [
            'Health Tourism fournit les informations de contact pour localiser de manière efficace un médecin ou tout service médical.',
            'Trouvez des cliniques, hôpitaux, laboratoires, etc.',
            'Informez-vous sur les traitements en Amérique latine, obtenez plus de détails et partagez-les avec vos contacts.',
            'Trouvez la localisation de votre médecin, numéro téléphone, votre courriel électronique et ayez ces informations sur place à tout moment.',
            'Effectuez une recherche par nom de médecin, spécialité ou service de manière rapide et efficace.'
          ],
          androidLink: 'https://play.google.com/store/apps/details?id=com.creativa.medical.locator',
          appleLink: 'https://itunes.apple.com/tj/app/health-tourism/id957342956?mt=8',
          videourl: ''
        },
        /*       {
                id: 3,
                image: './assets/images/productos/productos-img/JCS2.webp',
                title: 'APP 3',
                char1: 'Pro quis mutat omittantur et.',
                char2: 'Vis te paulo prompta molestiae.',
                char3: 'Ea everti deterruisset sea.',
                char4: 'Est velit nullam ut.',
                char5: 'Pri illum lucilius id.',
                androidLink: '',
                appleLink: ''
              }, */
        {
          id: 3,
          image: './assets/images/productos/productos-img/SS2.webp',
          title: 'Smart Stadium', /* If this is the name of the app, it should be restored back to "Smart Stadium" */
          description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait',
          chars: [
            'Fast food à l’heure.',
            'Vous pouvez le récupérer quand il soit prêt ou le recevoir à l’endroit où vous vous trouvez.',
            'Évitez de faire de longues queues pour commander.',
            'Idéal pour le secteur du stade avec la possibilité d’intégrer des restaurants.'
          ],
          androidLink: '',
          appleLink: '',
          videourl: ''
        },
        {
          id: 4,
          image: './assets/images/productos/productos-img/LAB-09.webp',
          title: 'Visible Outsource',
          description: 'Visible Outsorce (VO) est une suite dédiée aux processus de recherche de personnel dont a l’habileté de créer et de gérer des offres d’emploi, et de capturer de manière homogène les informations des candidats, et de gérer les profils sélectionnés.',
          chars: [
            'Servir d’aide à différentes industries.',
            'Offre des emplois vacants.',
            'Prise homogène d’informations.'
          ],
          androidLink: 'https://play.google.com/store/apps/details?id=com.creativa.talentos',
          appleLink: 'https://apps.apple.com/sv/app/visible-outsource/id1196292847',
          videourl: ''
        },
        {
          id: 5,
          image: './assets/images/productos/productos-img/LAB-08.webp',
          title: 'Sircs',
          description: 'Communication immediate n’importe où, idéale pour le travail collaboratif, le partage de documents, de fichiers, d’audios ou de vidéos; Avec une sécurité de cryptage de haut niveau.',
          chars: [
            'Profils d’utilisateur.',
            'Configuration de sécurité.',
            'Stockage sécurisé.',
            'Contacts.',
            'Équipes de travail.',
            'Configurations de skin.',
            'Logo corporatif',
            'Server d’authentification.',
            'Administration d’utilisateurs.',
            'Gestion du chat (clés chiffrées).',
            'Exclusif par entreprise.',
            'Stockage local.'
          ],
          androidLink: '',
          appleLink: '',
          videourl: ''
        },
        {
          id: 6,
          image: './assets/images/productos/productos-img/LAB-07.webp',
          title: 'Collect App',
          description: 'Spécialisé à l’incrémentation de l’efficacité et la réduction de l’impact des impayments dans les résultats. Notre objectif principal est d’aider les entreprises à réduire les retards de paiement, à améliorer leurs liquidités et à augmenter la rentabilité de leurs opérations; optimiser et garantir le développement des collecteurs. Basé sur des services de géolocalisation, afin de chercher une localisation simple ou effectuer un suivi constant régi par un horaire, une génération d’alertes basées sur les données des collecteurs en utilisant l’application mobile. Cette suite est composée d’applications Web et mobiles et d’un robot enchargé d’analyser les lieux pour automatiser certaines tâches.',
          chars: [
            'Lieu opportun et informations de voyage.',
            'Gestion hors ligne et en ligne à travers application mobile.',
            'Libre-service.',
            'Configuration automatique et manuelle des routes.',
            'Actions en masse et filtres.',
            'Planifiez l’horaire basé à la configuration.'
          ],
          androidLink: '',
          appleLink: '',
          videourl: ''
        },
        {
          id: 7,
          image: './assets/images/productos/productos-img/LAB-10.webp',
          title: 'EuphoRHia',
          description: 'Les ressources humaines sont l’un des domaines les plus importants de l’entreprise et le travail grandit au même temps que l’entreprise s’expande; Euforh.ia dont aide votre entreprise à gérer les demandes, formulaires, permis de travail, etc. de manière digital afin d’éviter les formalités administratives. Donc, c’est aussi un moyen de sauver la planète; il a également la supervision sur le processus de paiement aux employés.',
          chars: [
            'Gestion des candidatures.',
            'Formulaires pour la gestion des candidatures.',
            'Le processus de conversion des candidats d’emploi en employés.',
            'Gestion des contrats.',
            'Permis de travail, heures supplémentaires, vacances, temps partiel, formation, etc. Gestion.',
            'Capable de gérer le Business Consortium.',
            'Configurations par entreprise.',
            'Les autorisations de sécurité sont attribuables aux entreprises et aux utilisateurs.',
            'Gestion des feuilles de paiement deux fois par semaines et tous les mois.',
            'Calcul automatique des feuilles de paiement.',
            'Configuration pour plusieurs feuilles de paiement, périodes et départements.',
            'Gestion de remises récurrentes et d’une seule fois.',
            'Gestion des incréments, bonus et commissions.',
            'Paiements bancaires et génération de fichiers de taxes.',
            'Notifications de paiement par courriel.',
            'Génération et calcul de finalisation de contrats.',
            'Calcul de l’indemnité de licenciement ou de démission volontaire.'
          ],
          androidLink: '',
          appleLink: '',
          videourl: ''
        }
      ];
    }

    return this.products;

  }
}
