import { Component, OnInit } from '@angular/core';

@Component({
  selector: "app-landing-testing",
  templateUrl: "./landing-testing.component.html",
  styleUrls: ["./landing-testing.component.css"],
})
export class LandingTestingComponent implements OnInit {
  currentYear: number;
  
  constructor() {}

  ngOnInit(): void {
    this.createForm()
     this.currentYear = new Date().getFullYear();
  }

  createForm() {
      (<any>window).hbspt.forms.create({
        region: "na1",
        portalId: "39939554",
        formId: "b4430ae6-1204-4aa0-ba8d-f3c31c1cbb00",
        target: "#form",
      });
    }
}
