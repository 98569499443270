import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {LatestPostsService} from '../../../../services/latest-posts.service';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.css']
})
export class PostComponent implements OnInit {
  id: number;
  public post: any;
  public url;
  public body;

  constructor(public bsModalRef: BsModalRef, public  postService: LatestPostsService) { }

  ngOnInit() {
    this.post = this.postService.lates.find(element => {
      return element.id === this.id;
    });
    this.url = 'url(\'' + this.post.banner + '\')';
    this.body = this.post.body;
  }

}
