import {Injectable} from '@angular/core';
import data_json  from '../../assets/constants.json'

interface Quote {
  quote: string;
  author: string;
}
@Injectable()
export class QuotesSliderService {
  quotes: Quote[] = data_json.quotes;
}
