import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";

interface Partners {
  title: string;
  img: string;
}
@Injectable()
export class PartnersFooterService {

  getPartners(): Observable<Partners[]> {
    return of(this.partners);
  }
  private partners: Partners[] = [
    {
      title: "Microsoft",
      img: "./assets/images/nosotros/iconos/min-partner-01.webp",
    },
    {
      title: "Salesforce",
      img: "./assets/images/nosotros/iconos/salesforce.webp",
    },
    {
      title: "Odoo",
      img: "./assets/images/nosotros/iconos/odoo-white-logo.png",
    },
    {
      title: "ISTQB",
      img: "./assets/images/nosotros/iconos/min-partner-03.webp",
    },
    {
      title: "ISQI",
      img: "./assets/images/nosotros/iconos/min-partner-02.webp",
    },
    {
      title: "Brightest",
      img: "./assets/images/nosotros/iconos/min-partner-04.webp",
    },
  ];
}
