import { ActivatedRoute } from '@angular/router';

import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';

import { EmailService } from '../../services/email.service';
import { PostComponent } from './components/post/post.component';
import { default as data_json } from '../../../assets/constants.json';
import EventValuesAnalytics from '../../enums/EventValuesAnalytics';
import { FormValidation } from '../../utils/FormValidation';

declare var jQuery: any;
declare var $: any;
import * as AOS from 'aos';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterViewInit {
  @ViewChild('parentModal') parentModal: ModalDirective;
  @ViewChild('childModal') childModal: ModalDirective;
  @ViewChild('thirdModal') thirdModal: ModalDirective;
  @ViewChild('messageModal') messageModal: ModalDirective;
  @ViewChild('christmasModal') christmasModal: ModalDirective;


  // frm: FormGroup;
  contactForm: FormGroup;

  bsModalRef: BsModalRef;

  messageSentSuccess: boolean;
  messageSentError: boolean;

  message: string;

  nameError: string = '';
  phoneError: string = '';
  companyError: string = '';
  emailError: string = '';
  messageError: string = '';

  id: number;
  title: string;
  description: string;
  date: string;
  charact: string;

  lang = 'es';
  currentLang: string;
  sliderLang: string;

  showCreakindButton = false;
  showServiciosButton = false;
  showTalentButton = false;
  showNosotrosButton = false;
  showLabButton = false;
  showUniversityButton = false;
  showClientesButton = false;

  slideAutoPlay = false;
  slideImages: string[] = [
    '/assets/images/home/imagenes/PantallasSlide-Landing-01.webp',
    '/assets/images/home/imagenes/PantallasSlide-Landing-02.webp',
    '/assets/images/home/imagenes/PantallasSlide-Landing-03.webp',
    '/assets/images/home/imagenes/PantallasSlide-Landing-04.webp',
    '/assets/images/home/imagenes/PantallasSlide-Landing-05.webp',
    '/assets/images/home/imagenes/PantallasSlide-Landing-06.webp',
    '/assets/images/home/imagenes/PantallasSlide-Landing-07.webp'
  ];

  quotesSlideImages: string[] = [
    // '/assets/images/home/slider/no-text/bebe_monitor_creativa_consultores.webp',
    '/assets/images/home/slider/no-text/empresa_energia_creativa_consultores.webp',
    '/assets/images/home/slider/no-text/empresa_financiera_creativa_consultores.webp',
    '/assets/images/home/slider/no-text/empresa_legal_creativa_consultores.webp',
    '/assets/images/home/slider/no-text/servicio_ti_creativa_consultores.webp',
    '/assets/images/home/slider/no-text/slide_angular_hit.webp'
  ];

  welcomeSlideImages: string[] = [
    '/assets/images/home/imagenes/cover.webp',
    '/assets/images/home/welcomeSlider/banner_curso_game_creativa.webp',
    '/assets/images/home/welcomeSlider/banner_salesforce_creativa.webp'
  ];

  public quoteIndex = 0;
  public welcomeSliderIndex = 0;

  public coverHeight = '600px';

  constructor(
    public _es: EmailService,
    public translate: TranslateService,
    private modalService: BsModalService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    AOS.init();
    this.initializeForm();

    if (window.innerHeight <= window.innerWidth) {
      this.coverHeight = (window.innerWidth * 0.51) + 'px';
    } else {
      this.coverHeight = (window.innerHeight - 154) + 'px';
    }

    this.currentLang = this.translate.currentLang;
    this.sliderLang = this.translate.getBrowserLang();
    const p = this.activatedRoute.snapshot.queryParams;
    if (p.contact === 'true') {
      setTimeout(() => {
        this.scrollToContactForm();
      }, 500);

    }

    //Validacion de idioma del formulario
    this.createForm()

    /*setTimeout(() => {
      this.christmasModal.show();
    }, 500);*/
  }

  ngAfterViewInit() {

    this.translate.onTranslationChange.subscribe(
      (event: TranslationChangeEvent) => {
        this.sliderLang = event.lang;

        //carrousel de imagenes (español e ingles)
        if (this.sliderLang === 'fr') {
          this.sliderLang = 'en';
        }

        this.slideImages = [
          '/assets/images/home/imagenes/PantallasSlide-Landing-01' + this.sliderLang + 'webp',
          '/assets/images/home/imagenes/PantallasSlide-Landing-02.webp',
          '/assets/images/home/imagenes/PantallasSlide-Landing-03' + this.sliderLang + 'webp',
          '/assets/images/home/imagenes/PantallasSlide-Landing-04' + this.sliderLang + 'webp',
          '/assets/images/home/imagenes/PantallasSlide-Landing-05' + this.sliderLang + 'webp',
          '/assets/images/home/imagenes/PantallasSlide-Landing-06' + this.sliderLang + 'webp',
          '/assets/images/home/imagenes/PantallasSlide-Landing-07' + this.sliderLang + 'webp'
        ];


        //Validacion de idioma del formulario
        this.currentLang = event.lang;
        this.createForm()
      }
    );

    /*EL BOTONO HACE UN SCROLL AUTOMATICO AL FORMULARIO DE CONTACTO*/
    $('#homeButton').click(function () {
      $('html,body').animate(
        {
          scrollTop: $('.bg-10').offset().top
        },
        'slow'
      );
    });

    // Un bug de slideshow que hace mostrar los puntos a un lado de la pantalla, pero con el siguiente codigo se corrige
    $('ul.slick-dots li').css('display', 'inline-block');
    console.log(this.lang)
  }

  scrollToContactForm() {
    $('html,body').animate(
      {
        scrollTop: $('.bg-10').offset().top
      },
      'slow'
    );
  }

  /*PRIMERA VENTANA MODAL*/
  showParentDialog(data): void {
    this.id = data.id;
    this.title = data.title;
    this.description = data.description;
    this.date = data.startDate;
    this.charact = data.characteristics;

    this.parentModal.show();
  }

  /*SEGUNDA VENTANA MODAL*/
  showFormDialog(): void {
    this.childModal.show();
    this.parentModal.hide();
  }

  /*TERCERA VENTANA MODAL*/
  showSuccessDialog(): void {
    this.childModal.hide();
    this.thirdModal.show();
  }

  /*onSubmit(form:FormGroup){ }*/

  submitForm(form: FormGroup) {
    if (!form.invalid) {
      const cliente = {
        email: form.controls.email.value,
        subject: 'Contacto',
        name: form.controls.name.value,
        phone: form.controls.phoneNumber.value,
        company: form.controls.company.value,
        message:
          '<strong>Empresa: </strong><p>' +
          form.controls.company.value +
          '</p><br>' +
          '<strong>Telefono: </strong><p>' +
          form.controls.phoneNumber.value +
          '</p><br>' +
          '<p>' +
          form.controls.message.value +
          '</p>'
      };
      this._es.sendActivationEmail(cliente).subscribe(
        res => { },
        error => {
          this.messageSentError = true;
          setTimeout(() => {
            this.messageSentError = false;
          }, 3000);
        },
        () => {
          this.messageModal.show();
          this.messageSentSuccess = true;
          this.contactForm.reset();
          setTimeout(() => {
            this.messageModal.hide();
            this.messageSentSuccess = false;
          }, 3000);
          this.sendFormAnalytics();
          this.contactForm.reset();
        }
      );
    } else {
      const formErrors = FormValidation.getErrors(this.contactForm);

      this.translate.get(formErrors['name'].msj).subscribe(v => {
        this.nameError = v.replace('*', formErrors['name'].length);
      });
      this.translate.get(formErrors['email'].msj).subscribe(v => {
        this.emailError = v.replace('*', formErrors['email'].length);
      });
      this.translate.get(formErrors['phoneNumber'].msj).subscribe(v => {
        this.phoneError = v.replace('*', formErrors['phoneNumber'].length);
      });
      this.translate.get(formErrors['message'].msj).subscribe(v => {
        this.messageError = v.replace('*', formErrors['message'].length);
      });
      this.translate.get(formErrors['company'].msj).subscribe(v => {
        this.companyError = v.replace('*', formErrors['company'].length);
      });
    }
  }


  sendFormAnalytics() {
    /*(<any>window).ga('send', 'event', {
      eventCategory: data_json.categoriesAnalytics.forms,
      eventLabel: data_json.labelsAnalytics.formHome,
      eventAction: data_json.actionsAnalytics.sendFormHome,
      eventValue: EventValuesAnalytics.sendHomeForm
    });*/
  }

  initializeForm() {
    this.contactForm = new FormGroup({
      name: new FormControl(null, [
        Validators.required,
        Validators.minLength(5),
        Validators.pattern('[a-zA-Z ]*')
      ]),
      phoneNumber: new FormControl(null, [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern('[0-9]*')
      ]),
      company: new FormControl(null, [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern('[a-zA-Z ]*')
      ]),
      email: new FormControl(null, [
        Validators.required,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')
      ]),
      message: new FormControl(null, [Validators.required])
    });
  }

  openPost(id) {
    const initialState = {
      id: id
    };
    this.bsModalRef = this.modalService.show(PostComponent, {
      initialState,
      class: 'modal-lg'
    });
  }

  onQuotesIndexChanged(idx: number) {
    this.quoteIndex = idx;
  }

  onWelcomeSliderIndexChanged(idx: number) {
    this.welcomeSliderIndex = idx;
  }

  onIndexChanged(idx: number) {
    this.showCreakindButton = false;
    this.showServiciosButton = false;
    this.showTalentButton = false;
    this.showNosotrosButton = false;
    this.showLabButton = false;
    this.showUniversityButton = false;
    this.showClientesButton = false;
    if (idx === 0) {
      this.showCreakindButton = true;
    } else if (idx === 1) {
      this.showNosotrosButton = true;
    } else if (idx === 2) {
      this.showServiciosButton = true;
    } else if (idx === 3) {
      this.showLabButton = true;
    } else if (idx === 4) {
      this.showClientesButton = true;
    } else if (idx === 5) {
      this.showUniversityButton = true;
    } else if (idx === 6) {
      this.showTalentButton = true;
    }

    // Un bug de slideshow que hace mostrar los puntos a un lado de la pantalla, pero con el siguiente codigo se corrige
    $('ul.slick-dots li').css('display', 'inline-block');
  }

  scrollTo(elementId: string) {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  createForm() {
    if (this.currentLang == "en" || this.currentLang == "fr") {

      (<any>window).hbspt.forms.create({
        region: "na1",
        portalId: "39708487",
        formId: "35c1270f-7dff-4a1a-9ab8-9108d9cc3a18",
        target: '#hbspt-form'
      });
    } else {

      (<any>window).hbspt.forms.create({
        region: "na1",
        portalId: "39708487",
        formId: "3ee9f2e8-8116-41a5-affc-823568622bb7",
        target: '#hbspt-form'
      });
    }
  }

}
