import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ModalModule} from 'ngx-bootstrap/modal';

import {ModalComponent} from '../components/modal/modal.component';

@NgModule({
  imports: [
    CommonModule,
    ModalModule.forRoot()
  ],
  declarations: [
    ModalComponent,
  ],
  exports: [
    ModalComponent,
    ModalModule,
  ]
})
export class SharedModule {
}
