import { Component, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-home-layout',
  templateUrl: './home-layout.component.html',
  styleUrls: ['./home-layout.component.css']
})
export class HomeLayoutComponent implements OnDestroy {
  private subscription: Subscription;

  constructor(private router: Router) {
    this.subscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      /*if (typeof (<any>window).gtag === 'function') {
        (<any>window).gtag('event', 'page', event.urlAfterRedirects);
        (<any>window).gtag('event', 'pageview', event.urlAfterRedirects);
      } else {
        console.error('gtag is not defined');
      }*/
    });

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onActivate(e, outlet) {
    outlet.scrollTop = 0;
  }
}
