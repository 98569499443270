import {Injectable} from '@angular/core';

interface Course {
  id: number;
  title: string;
  description: string;
  startDate: string;
  characteristics: string;
}
@Injectable()
export class CoursesService {
  courses: Course[] =  [
    {
      id: 1,
      title: 'Certificación iSQl',
      description: 'Curso de iSQL',
      startDate: '14 de febrero',
      characteristics: '- Nulla consequat massa quis enim.\n- Donec pede justo.\n- Nnascetur ridiculus mus. Donec quam felis.'
    },
    {
      id: 2,
      title: 'Diplomado CMAPl',
      description: 'Curso de CMAPL',
      startDate: '25 de marzo',
      characteristics: '- Nulconsectetuer adipiscing elit.\n- Aenean commodo ligula eget dolor.\n- Cum sociis natoque penatibus et.'
    },
    {
      id: 3,
      title: 'SCRUM',
      description: 'Curso de SCRUM',
      startDate: '7 de abril',
      characteristics: '- magnis dis parturient montes.\n- nascetur ridiculus mus.\n- Lorem ipsum dolor sit amet.'
    },
  ];


}
