import {NgModule} from '@angular/core';

import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SlideshowModule} from 'ng-simple-slideshow';
import { BrowserModule } from '@angular/platform-browser';

import {SharedModule} from '../../shared/shared.module';
import {BaseTranslateModule} from '../../shared/base-translate.module';
import {HomeComponent} from './home.component';
import {PostComponent} from './components/post/post.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    SlideshowModule,
    BaseTranslateModule,
    BrowserModule,
  ],
  declarations: [
    HomeComponent,
    PostComponent,
  ],
  entryComponents: [
    PostComponent
  ],
  providers: []
})
export class HomeModule {
}
