import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";

interface Customer {
  title: string;
  img: string;
}

@Injectable()
export class CustomersService {

  getCustomers(): Observable<Customer[]> {
    return of(this.customers);
  }

  private customers: Customer[] = [
    {
      title: "CITI Bank",
      img: "./assets/images/nuestros-clientes/iconos/cliente-01.webp",
    },
    {
      title: "Banco G&T Continental",
      img: "./assets/images/nuestros-clientes/iconos/cliente-02.webp",
    },
    {
      title: "DAVIVIENDA",
      img: "./assets/images/nuestros-clientes/iconos/cliente-03.webp",
    },
    {
      title: "Banco Promerica",
      img: "./assets/images/nuestros-clientes/iconos/cliente-05.webp",
    },
    // {title: 'MITRATECH', img: './assets/images/nuestros-clientes/iconos/cliente-16.webp'},
    {
      title: "KMStandards",
      img: "./assets/images/nuestros-clientes/iconos/cliente-17.webp",
    },
    {
      title: "Kruger",
      img: "./assets/images/nuestros-clientes/iconos/cliente-13.webp",
    },
    {
      title: "Mozido",
      img: "./assets/images/nuestros-clientes/iconos/cliente-14.webp",
    },
    {
      title: "CieloIT",
      img: "./assets/images/nuestros-clientes/iconos/cliente-20.webp",
    },
    {
      title: "Accenture",
      img: "./assets/images/nuestros-clientes/iconos/cliente-18.webp",
    },
    {
      title: "Cuestamoras",
      img: "./assets/images/nuestros-clientes/iconos/cliente-26.webp",
    },
    {
      title: "APPtividad",
      img: "./assets/images/nuestros-clientes/iconos/cliente-25.webp",
    },
    {
      title: "Grupo Babel",
      img: "./assets/images/nuestros-clientes/iconos/cliente-19.webp",
    },
    {
      title: "DSB Mobile",
      img: "./assets/images/nuestros-clientes/iconos/cliente-21.webp",
    },
    {
      title: "Bayer",
      img: "./assets/images/nuestros-clientes/iconos/cliente-27.webp",
    },
    {
      title: "SISA",
      img: "./assets/images/nuestros-clientes/iconos/cliente-04.webp",
    },
    {
      title: "ASSA Seguros",
      img: "./assets/images/nuestros-clientes/iconos/cliente-28.webp",
    },
    {
      title: "SIMAN",
      img: "./assets/images/nuestros-clientes/iconos/cliente-06.webp",
    },
    {
      title: "Digicel",
      img: "./assets/images/nuestros-clientes/iconos/cliente-07.webp",
    },
    {
      title: "Tigo",
      img: "./assets/images/nuestros-clientes/iconos/cliente-12.webp",
    },
    {
      title: "Arrocera San Francisco",
      img: "./assets/images/nuestros-clientes/iconos/cliente-08.webp",
    },
    {
      title: "Grupo UNICOMER",
      img: "./assets/images/nuestros-clientes/iconos/cliente-09.webp",
    },
    {
      title: "AES",
      img: "./assets/images/nuestros-clientes/iconos/cliente-10.webp",
    },
    // {title: 'Plaza Malta', img: './assets/images/nuestros-clientes/iconos/cliente-11.webp'},
    {
      title: "Avianca",
      img: "./assets/images/nuestros-clientes/iconos/cliente-15.webp",
    },
    {
      title: "Equifax",
      img: "./assets/images/nuestros-clientes/iconos/cliente-22.webp",
    },
    {
      title: "Gesel",
      img: "./assets/images/nuestros-clientes/iconos/cliente-23.webp",
    },
    {
      title: "Pollo Campero",
      img: "./assets/images/nuestros-clientes/iconos/cliente-24.webp",
    },
    {
      title: "CAESS",
      img: "./assets/images/nuestros-clientes/iconos/cliente-29.webp",
    },
    {
      title: "Omnisport",
      img: "./assets/images/nuestros-clientes/iconos/cliente-30.webp",
    },
  ];

}
