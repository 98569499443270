// header.component.ts
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {
  public values = [{n: 'FRANCES', v: 'fr'}, {n: 'ENGLISH', v: 'en'}, {n: 'ESPAÑOL', v: 'es'}];
  public shouldShowSkype = false;
  public selectedLanguage = '';

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    this.selectedLanguage = this.translate.currentLang.toUpperCase();
  }

  useLanguage(language: string) {
    localStorage.setItem('userLang', language);
    this.translate.use(language);
    this.selectedLanguage = language.toUpperCase();
  }
}
